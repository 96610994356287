import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Signal,
  SimpleChanges,
  computed
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PermissionService } from '@services/internal/permission.service';
import { UsersService } from '@services/users.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { AlertsService } from '@services/internal/alerts.service';
import { environment } from '@environment';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { add } from 'date-fns';
import { DestroySubscriptions } from '@shared/classes/destroy-subscriptions';
import { EventTemplateBuilder } from '../../helpers/event-template-builder';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ModalEditContactsComponent } from 'src/app/features/directory/facilities/partials/modals/modal-edit-contacts/modal-edit-contacts.component';
import { BehaviorSubject, firstValueFrom, map, Observable, takeUntil, tap } from 'rxjs';
import { FacilityService } from '@services/facility.service';
import { EventModel, ContactModel, FacilityModel, PONumberModel, ProcedureModel, UserModel, UserTimeZone } from '@shared/models';
import { IICalEvent } from '@shared/interfaces';
import { EEventCreditStatus, EEventTypes } from '@shared/enum';
import { TrackById } from '@shared/utils/form-elements/track-by';
import { createICalEvent } from '@shared/utils/create-ical-event';
import { FilterUniqueArray } from '@shared/utils/arrays/filter-unique-array';
import { showTimeHelper } from '@shared/helpers/show-time';
import { MatAutocompleteRequireID, MatAutocompleteRequireMath } from '@shared/validators/mat-autocomplete-require-math';
import { TIcons } from '@shared/type/icons.type';
import { TEventType } from '@shared/type/index.type';
import { EventsStoreService } from '../../services/events-store.service';
import { CreateMediumEntityConfig } from '@constants';
import { TranslationsKeys } from '@shared/type/i18n.type';
import { downloadICalEvent } from '@shared/utils/cross-platform/download-ical-event';
import { GlobalStoreService } from '@services/internal/global-store.service';
import { SortEnumsOriginalOrder } from '@shared/utils/sort-enums-original-order';
import { IAIEventCreation } from '@shared/interfaces/events/ai-event-creation';

@Component({
  selector: 'app-form-step-first',
  templateUrl: './form-step-first.component.html',
  styleUrls: ['./form-step-first.component.scss']
})
export class FormStepFirstComponent extends DestroySubscriptions implements OnInit, OnChanges {
  @Input() set event(params: { data: EventModel; duplicatedEvent?: boolean }) {
    const { data, duplicatedEvent = false } = params;
    this.eventTimeZone = {
      timeZone: data.timeZone,
      offset: data.offset
    };
    this.showRepTime = this.userTimeZone.offset !== this.eventTimeZone.offset && this.eventTimeZone.offset !== null;
    this.setData(data);
    this.changeEventType(duplicatedEvent);
    if (data.facility?.facilityContacts) {
      this.facilityContact = data.facility.facilityContacts;
      this.filterFacilityContactOption();
    }
  }
  @Input() set permittedRepresentative(data: UserModel) {
    this.setRepresentative(data);
  }
  @Input() set predefinedEventType(evType: TEventType) {
    setTimeout(async () => {
      const isEventTypeExist = this.availableEventTypes.findIndex(t => t.key === evType);
      const eventType = isEventTypeExist === -1 ? 'CASE' : evType;
      this.required.patchValue({ eventType });
      await this.changeEventType();
    });
  }
  @Input() allowEdit: boolean = false;
  @Input() isLoading: boolean = false;
  @Input() createEntityFlow: boolean = false;
  @Input() selectedFacilityContact: ContactModel[] = [];
  @Input() poNumbers: PONumberModel[] = [];
  @Input() predefinedDateTime: { dateTime: Date; setTime: boolean };
  @Input() excludeEventTypes: TEventType[] = [];
  @Input() allowCreateAndInNewIconInAutocompletes: boolean = true;
  @Input() modifiers: { modifierName: string }[] = [];
  @Input() set aAIEventCreationData(data: IAIEventCreation) {
    if (data) {
      setTimeout(() => {
        this.setAIData(data);
      }, 100);
    }
  }

  @Output() assignmentsValidatorUpdateEmitter = new EventEmitter();
  @Output() formNameValueChangeEmitter = new EventEmitter<UntypedFormGroup>();
  @Output() assignFacilityEmitter = new EventEmitter<{ custodyId: string; facilityId: string; callBack?: () => void }>();
  @Output() assignPhysicianEmitter = new EventEmitter<{ custodyId: string; physicianId: string }>();
  @Output() selectedCustodyEmitter = new EventEmitter<UserModel>();
  @Output() removeFacilityContactEmitter = new EventEmitter<string[]>();
  @Output() selectFacilityContactEmitter = new EventEmitter<ContactModel>();
  @Output() selectModifierEmitter = new EventEmitter<string>();
  @Output() removeModifierEmitter = new EventEmitter<{ modifierName: string }>();

  config: EventTemplateBuilder = new EventTemplateBuilder();
  representativeWasSet: boolean = false;
  eventTimeZone: UserTimeZone = {
    timeZone: null,
    offset: null
  };
  isActiveLocalTime: boolean = true;
  modifiersAutocomplete: string[] = [];
  required: UntypedFormGroup;
  showRepTime: boolean = false;
  showTime: boolean = true;
  userTimeZone: UserTimeZone;
  currentUser: UserModel = UsersService.getUser();
  eventsData: IICalEvent[];
  facilityContact: ContactModel[] = [];
  facilityContactFiltered: ContactModel[] = [];
  eventDetail: EventModel = new EventModel();
  showEditContactIcon: boolean = false;
  transfersExist$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  availableEventTypes: Record<string, string>[] = [];
  isShowInputsInvalidStateOnInit: boolean = false;

  readonly hasAssignedItems: Signal<boolean> = computed(() => {
    const store = this.eventsStoreService.store$.value;
    return !!(store.assignedDevicesOriginal?.length || store.assignedPacksOriginal?.length);
  });
  readonly devicesPriceChanged$: Observable<boolean> = this.eventsStoreService.store$.pipe(map(s => s.devicesPriceChanged));
  readonly inventoryRequestsExists$: Observable<boolean> = this.eventsStoreService.store$.pipe(map(s => s.inventoryRequestsExists));
  readonly isMobile$: BehaviorSubject<boolean> = GlobalStoreService.isMobile$;
  readonly eEventTypes: Record<string, TranslationsKeys> = EEventTypes;
  readonly trackBy = TrackById;
  readonly eEventCreditStatus: Record<string, TranslationsKeys> = EEventCreditStatus;
  //CC-5245
  readonly isPhysicianOptional: boolean = UsersService.getUser().organization.isCaseNoPhysicianEnabled;

  constructor(
    public permissionService: PermissionService,
    private formBuilder: UntypedFormBuilder,
    private alertsService: AlertsService,
    private ref: ChangeDetectorRef,
    private dialog: MatDialog,
    private facilityService: FacilityService,
    private eventsStoreService: EventsStoreService
  ) {
    super();
    this.createForm();
    this.userTimeZone = UsersService.userTimeZone;
    this.showRepTime = this.userTimeZone.offset !== this.eventTimeZone?.offset && this.eventTimeZone?.offset !== null;
  }

  ngOnInit(): void {
    this.formChanges();
    // Check permissions, business logic to show available event types, sort alphabetically
    this.availableEventTypes = SortEnumsOriginalOrder(EEventTypes)
      .filter(
        type =>
          !this.excludeEventTypes.some(t => t === type.key) &&
          (type.key === 'STOCK_ADJUSTMENT'
            ? this.currentUser.role === 'ADMIN' &&
              (this.currentUser.customPermissions?.invDeactivationAndStockEventCreationAllowed || !this.createEntityFlow)
              ? type
              : null
            : type)
      )
      .sort((a, b) => (a.key > b.key ? 1 : -1));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.predefinedDateTime?.currentValue?.dateTime) {
      this.setDateTime();
    }
  }

  setModifiers(procedure: ProcedureModel): void {
    this.modifiersAutocomplete = [];
    this.modifiersAutocomplete = procedure.modifiers || [];
    this.eventsStoreService.store$.next({ ...this.eventsStoreService.store$.value, ...{ modifiers: [] } });
  }

  downloadEvent(): void {
    const events: string = createICalEvent(this.eventsData);
    downloadICalEvent(`${this.eventsData[0].summary}.ics`, events, this.eventsData[0]);
  }

  selectModifier(value: string): void {
    this.required.markAsTouched();
    this.required.markAsDirty();
    this.selectModifierEmitter.emit(value);
  }

  removeModifier(item: { modifierName: string }): void {
    this.required.markAsTouched();
    this.required.markAsDirty();
    this.removeModifierEmitter.emit(item);
  }

  async setFacilityContacts(facility: FacilityModel): Promise<void> {
    if (!facility || this.required.get('eventType')?.value !== 'STOCKING_ORDER') {
      return;
    }

    this.facilityContact = [];
    this.facilityContact = facility.facilityContacts
      .map(contact => {
        if (!contact.phone && !contact.email && !contact.additionalEmail) {
          return null;
        }
        if (!contact.name) {
          contact.name = 'Untitled';
        }
        return contact;
      })
      .filter(contact => contact !== null);
    this.ref.markForCheck();
    this.removeFacilityContactEmitter.emit(this.selectedFacilityContact.map(c => c.id));

    if (this.facilityContact?.length) {
      this.required.get('facilityContact').enable();
    } else {
      const canEditSelfOnly: boolean = this.permissionService.isGranted('directory', 'canEditSelfOnlyFacility');
      const isCurrentUserCreator: boolean = this.permissionService.isCurrentUser(facility.createdBy?.id);
      const canEdit = this.permissionService.isGranted('directory', 'editFacility') && (canEditSelfOnly ? isCurrentUserCreator : true);

      if (!canEdit || facility.state === 'INACTIVE') {
        this.alertsService.showError('shared.alerts.errorMessages.editFacilityContact');
        return;
      }

      this.openEditContactsModal(facility);
      this.ref.detectChanges();
    }
  }

  showInvoiceNumber(): boolean {
    return (
      (this.eventDetail?.eventStatus === 'COMPLETED' ||
        this.eventDetail?.eventStatus === 'PAID' ||
        this.eventDetail?.eventStatus === 'CANCELLED') &&
      this.eventDetail.eventType !== 'DAMAGE' &&
      this.eventDetail.eventType !== 'RETURN' &&
      !this.eventDetail?.facilityStockEvent
    );
  }

  async openEditContactsModal(facility: FacilityModel): Promise<void> {
    const dialogConfig: MatDialogConfig = { ...CreateMediumEntityConfig };
    dialogConfig.data = { facility };

    const dialogRef = this.dialog.open(ModalEditContactsComponent, dialogConfig);
    this.alertsService.showWarning('shared.alerts.errorMessages.noFacilityContacts');
    const data: { updateContacts: boolean; facility: FacilityModel } = await firstValueFrom(dialogRef.afterClosed());

    if (data?.updateContacts) {
      this.facilityService
        .updateFacility(facility.id, data.facility)
        .pipe(
          takeUntil(this.subscriptions),
          tap(async () => {
            const facilityData: FacilityModel = await firstValueFrom(
              this.facilityService.getFacility(this.required.get('facilityId').value.id)
            );
            this.setFacilityContacts(facilityData);
          })
        )
        .subscribe();
    } else {
      this.required.get('facilityContact').enable();
      this.showEditContactIcon = true;
    }
  }

  changeDate(event: MatDatepickerInputEvent<any> = null, dateTime?: Date): void {
    if (!this.showRepTime || !this.showTime) {
      const d = add(new Date(event?.value || dateTime), { hours: 12 }).toISOString();
      this.required.patchValue({ date: d });
      return;
    }
    const time: string = this.required.get('time').value;
    let hours: number = 12;
    let minutes: number = 0;
    if (time?.length === 5) {
      hours = +time.split(':')[0];
      minutes = +time.split(':')[1];
    }

    const datetime: Date = zonedTimeToUtc(new Date(event?.value || dateTime), this.userTimeZone.timeZone);
    const date: string = add(datetime, { hours, minutes }).toISOString().slice(0, -1);
    const representativeDate: string = utcToZonedTime(
      zonedTimeToUtc(date, this.userTimeZone.timeZone),
      this.eventTimeZone.timeZone
    ).toISOString();

    this.required.patchValue({ date });
    this.required.patchValue({ representativeDate });
  }

  filterFacilityContactOption(): void {
    this.facilityContactFiltered = FilterUniqueArray(this.facilityContact, this.selectedFacilityContact);
  }

  async changeEventType(skipClearState?: boolean): Promise<void> {
    const eventType: TEventType = this.required.get('eventType').value;
    const representative: UserModel = { ...this.required.get('representativeId').value };

    if (this.createEntityFlow && !skipClearState) {
      this.required.reset();
      this.eventsStoreService.destroy();
      this.required.get('eventType').setValue(eventType);
      if (this.currentUser.role === 'SALES') {
        this.required.get('representativeId').setValue(representative);
        this.chooseRepresentative(representative);
      }
    }

    this.required.enable();

    this.config = new EventTemplateBuilder(eventType);
    this.showTime = showTimeHelper(eventType);

    const procedureValidator = this.config.procedure
      ? [Validators.required, MatAutocompleteRequireMath, MatAutocompleteRequireID]
      : [MatAutocompleteRequireMath];

    const physicianValidator =
      this.config.physician &&
      eventType !== 'DAMAGE' &&
      eventType !== 'OTHER' &&
      eventType !== 'SAMPLE' &&
      (eventType === 'CASE' ? !this.isPhysicianOptional : true)
        ? [Validators.required, MatAutocompleteRequireMath, MatAutocompleteRequireID]
        : [MatAutocompleteRequireMath];

    const facilityValidator =
      this.config.facility && eventType !== 'DAMAGE' && eventType !== 'OTHER' && eventType !== 'SAMPLE' && eventType !== 'RETURN'
        ? [Validators.required, MatAutocompleteRequireMath, MatAutocompleteRequireID]
        : [MatAutocompleteRequireMath];
    const facilityContactValidator = [MatAutocompleteRequireMath];
    const productValidator = [MatAutocompleteRequireMath];
    const healthRecordIdValidator = eventType === 'TRIAL' ? [Validators.required] : [];
    const representativeIdvalidator = eventType === 'STOCK_ADJUSTMENT' ? [] : [Validators.required, MatAutocompleteRequireMath];
    const nameValidator = eventType !== 'CASE' || !this.createEntityFlow ? [Validators.required] : [];

    this.required.get('facilityContact').disable();

    this.required.get('name').setValidators(nameValidator);
    this.required.get('procedureId').setValidators(procedureValidator);
    this.required.get('physicianId').setValidators(physicianValidator);
    this.required.get('facilityId').setValidators(facilityValidator);
    this.required.get('facilityContact').setValidators(facilityContactValidator);
    this.required.get('healthRecordId').setValidators(healthRecordIdValidator);
    this.required.get('representativeId').setValidators(representativeIdvalidator);

    this.required.get('name').updateValueAndValidity();
    this.required.get('procedureId').updateValueAndValidity();
    this.required.get('physicianId').updateValueAndValidity();
    this.required.get('facilityId').updateValueAndValidity();
    this.required.get('facilityContact')?.updateValueAndValidity();
    this.required.get('healthRecordId').updateValueAndValidity();
    this.required.get('representativeId').updateValueAndValidity();

    this.assignmentsValidatorUpdateEmitter.emit({
      productValidator,
      eventType
    });

    if (this.representativeWasSet) {
      this.required.controls['representativeId'].disable();
    }
    if (this.isActiveLocalTime) {
      this.required.get('representativeDate').disable();
      this.required.get('representativeTime').disable();
    }
    if (this.showTime && !this.required.controls['representativeId']?.value) {
      this.required.get('date').disable();
      this.required.get('time').disable();
      this.required.get('representativeDate').disable();
      this.required.get('representativeTime').disable();
    }
  }

  changeRepresentativeDate(event: MatDatepickerInputEvent<any>): void {
    const time: string = this.required.get('representativeTime').value;
    let hours: number = 12;
    let minutes: number = 0;
    if (time?.length === 5) {
      hours = +time.split(':')[0];
      minutes = +time.split(':')[1];
    }

    // Get local machine timeZone (datepicker always looks on local timeZone)
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const datetime: number = zonedTimeToUtc(event.value, timezone).getTime();
    const date: Date = add(datetime, { hours, minutes });

    const localDate: Date = utcToZonedTime(zonedTimeToUtc(date, this.eventTimeZone.timeZone), this.userTimeZone.timeZone);

    this.required.patchValue({ date: localDate });
    this.required.patchValue({ representativeDate: date });
  }

  changeRepresentativeTime(event: Event = null): void {
    if (!this.required.get('representativeDate').value) {
      this.required.get('representativeDate').setValue(new Date());
    }
    const dateUI: Date = this.required.get('representativeDate').value;
    const value: string = (<HTMLInputElement>event?.target)?.value;

    if (!event) {
      let copyDate: string | Date = utcToZonedTime(zonedTimeToUtc(dateUI, this.userTimeZone.timeZone), this.userTimeZone.timeZone);
      copyDate.setHours(12);
      copyDate = zonedTimeToUtc(copyDate, this.userTimeZone.timeZone).toISOString();
      this.required.patchValue({ date: copyDate });
      return;
    }

    let date: string | Date = utcToZonedTime(zonedTimeToUtc(dateUI, this.eventTimeZone.timeZone), this.eventTimeZone.timeZone);
    date.setHours(0);
    date.setMinutes(0);
    date = add(zonedTimeToUtc(date, this.eventTimeZone.timeZone), {
      hours: +value.split(':')[0],
      minutes: +value.split(':')[1]
    }).toISOString();

    let representativeDate: string | Date = utcToZonedTime(date, this.userTimeZone.timeZone);
    representativeDate.setHours(12);
    representativeDate = zonedTimeToUtc(representativeDate, this.userTimeZone.timeZone).toISOString().slice(0, -1);

    const representativeTimeHH = utcToZonedTime(date, this.userTimeZone.timeZone).getHours();
    const representativeTimeMM = utcToZonedTime(date, this.userTimeZone.timeZone).getMinutes();

    const h = representativeTimeHH < 10 ? '0' + representativeTimeHH : representativeTimeHH;
    const m = representativeTimeMM < 10 ? '0' + representativeTimeMM : representativeTimeMM;

    const representativeTime: string = `${h}:${m}`;
    this.required.patchValue({ date: representativeDate });
    this.required.patchValue({ time: representativeTime });
  }

  changeTime(event: Event = null, time?: string): void {
    if (!this.showRepTime) {
      return;
    }
    const dateUI: Date = this.required.get('date').value;
    if (!event && !time) {
      let copyDate: string | Date = utcToZonedTime(zonedTimeToUtc(dateUI, this.userTimeZone.timeZone), this.userTimeZone.timeZone);
      copyDate.setHours(12);
      copyDate = zonedTimeToUtc(copyDate, this.userTimeZone.timeZone).toISOString();
      this.required.patchValue({ representativeDate: copyDate });
      return;
    }
    const value: string = (<HTMLInputElement>event?.target)?.value || time;
    let date: string | Date = utcToZonedTime(zonedTimeToUtc(dateUI, this.userTimeZone.timeZone), this.userTimeZone.timeZone);
    date.setHours(0);
    date.setMinutes(0);
    date = add(zonedTimeToUtc(date, this.userTimeZone.timeZone), {
      hours: +value.split(':')[0],
      minutes: +value.split(':')[1]
    }).toISOString();

    const representativeTimeHH = utcToZonedTime(date, this.eventTimeZone.timeZone).getHours();
    const representativeTimeMM = utcToZonedTime(date, this.eventTimeZone.timeZone).getMinutes();

    let representativeDate: string | Date = utcToZonedTime(date, this.eventTimeZone.timeZone);
    representativeDate.setHours(12);
    representativeDate = zonedTimeToUtc(representativeDate, this.eventTimeZone.timeZone).toISOString().slice(0, -1);
    const h = representativeTimeHH < 10 ? '0' + representativeTimeHH : representativeTimeHH;
    const m = representativeTimeMM < 10 ? '0' + representativeTimeMM : representativeTimeMM;

    const representativeTime: string = `${h}:${m}`;
    this.required.patchValue({ representativeDate });
    this.required.patchValue({ representativeTime });
  }

  chooseRepresentative(representative: UserModel): void {
    this.required.get('date').enable();
    this.required.get('time').enable();
    this.showRepTime = this.userTimeZone.offset !== representative.offset && representative.timeZone !== null;
    this.eventTimeZone.timeZone = representative.timeZone;
    this.eventTimeZone.offset = representative.offset;

    if (representative.timeZone === null && representative.id) {
      this.alertsService.showWarning('shared.alerts.errorMessages.representativeDoesNotHaveTimeZone', 15000);
    }

    const predefinedDateTime: Date = this.predefinedDateTime?.dateTime || new Date();

    if (predefinedDateTime && predefinedDateTime instanceof Date) {
      this.changeDate(null, predefinedDateTime);
      const dateTimeString = predefinedDateTime;
      const h = dateTimeString.getHours() < 10 ? '0' + dateTimeString.getHours() : dateTimeString.getHours();
      const m = dateTimeString.getMinutes() < 10 ? '0' + dateTimeString.getMinutes() : dateTimeString.getMinutes();
      this.required.patchValue({ date: dateTimeString });
      if (this.predefinedDateTime?.setTime) {
        this.required.patchValue({ time: h + ':' + m });
      }
      this.changeTime(null, this.required.get('time').value);
    }
  }

  createForm() {
    this.required = this.formBuilder.group({
      name: [{ value: '', disabled: true }, [Validators.required, Validators.minLength(2)]],
      representativeId: [{ value: '', disabled: true }, [Validators.required, MatAutocompleteRequireMath, MatAutocompleteRequireID]],
      eventType: ['CASE', [Validators.required]],
      procedureId: [{ value: '', disabled: true }, [Validators.required, MatAutocompleteRequireMath, MatAutocompleteRequireID]],
      physicianId: [{ value: '', disabled: true }, [MatAutocompleteRequireMath, MatAutocompleteRequireID]],
      facilityId: [{ value: '', disabled: true }, [Validators.required, MatAutocompleteRequireMath, MatAutocompleteRequireID]],
      facilityContact: [{ value: '', disabled: true }, [MatAutocompleteRequireMath]],
      date: [{ value: '', disabled: true }, [Validators.required]],
      time: [{ value: '', disabled: true }],
      protocolNumber: [''],
      description: [''],
      principalInvestigator: [''],
      representativeDate: [{ value: '', disabled: true }, [Validators.required]],
      representativeTime: [{ value: '', disabled: true }],
      healthRecordId: ['', [Validators.maxLength(255)]]
    });
  }

  doIconAction(icon: TIcons, representativeTime: boolean = false): void {
    if (icon === 'close') {
      this.required.patchValue({ time: '', representativeTime: '' });
      if (representativeTime) {
        this.required.get('representativeTime').markAsTouched();
        this.changeRepresentativeTime();
      } else {
        this.required.get('time').markAsTouched();
        this.changeTime();
      }
    }

    if (icon === 'edit') {
      this.openEditContactsModal(this.required.get('facilityId').value);
    }
  }

  swapDateTime(): void {
    this.isActiveLocalTime = !this.isActiveLocalTime;
    if (this.isActiveLocalTime) {
      this.required.get('date').enable();
      this.required.get('time').enable();

      this.required.get('representativeDate').disable();
      this.required.get('representativeTime').disable();
    } else {
      this.required.get('date').disable();
      this.required.get('time').disable();

      this.required.get('representativeDate').enable();
      this.required.get('representativeTime').enable();
    }
  }

  isAvailableToChangeRepresentative(): boolean {
    return (
      this.permissionService.isRole('ADMIN') &&
      this.eventDetail.eventStatus === 'OPEN' &&
      !this.eventsStoreService.store$.value.assignedDevicesOriginal.length &&
      !this.eventDetail.reOpenedEvent
    );
  }

  checkFacilityCreditHold(facility: FacilityModel): void {
    if (facility.isCreditHold) {
      this.alertsService.showError('shared.alerts.errorMessages.facilityCreditHold');
    }
    this.eventsStoreService.store$.next({ ...this.eventsStoreService.store$.value, ...{ isFacilityCreditHold: facility.isCreditHold } });
  }

  private formChanges(): void {
    setTimeout(() => {
      this.formNameValueChangeEmitter.emit(this.required);
    });
    this.required.valueChanges.pipe(takeUntil(this.subscriptions)).subscribe(() => {
      this.formNameValueChangeEmitter.emit(this.required);
    });
  }

  private setData(data: EventModel): void {
    if (!data?.id || !this.required) {
      return;
    }
    const companyName = UsersService.getCompanyName();
    this.eventDetail = data;
    const date = utcToZonedTime(data.datetime, this.userTimeZone.timeZone);
    const repDate = utcToZonedTime(data.datetime, data.timeZone);
    const repH = repDate?.getHours() < 10 ? '0' + repDate?.getHours() : (repDate?.getHours() ?? '');
    const repM = repDate?.getMinutes() < 10 ? '0' + repDate?.getMinutes() : (repDate?.getMinutes() ?? '');

    const showTime: boolean = showTimeHelper(data.eventType, data.datetime);

    const h = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
    const m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    const representative = data.representative
      ? { id: data.representative.id, name: data.representative.name, role: data.representative.role }
      : {
          id: '',
          name: companyName
        };
    const physiciansNames = data.physicians.map(physician => physician.fullName);
    this.eventsData = [
      {
        start: date,
        end: date,
        timeZone: this.userTimeZone.timeZone,
        summary: data.name,
        description: `Procedure: ${data.procedure?.name || 'N/A'}, physician: ${physiciansNames.join(', ') || 'N/A'},
  comments: ${data.description || 'N/A'}`,
        location: data.facility?.name,
        url: `${environment.frontUrl}events/edit/${data.id}`,
        isAllDay: !showTime
      }
    ];

    this.modifiersAutocomplete = data.procedure?.modifiers || [];

    this.required.setValue({
      name: data.name,
      eventType: data.eventType,
      procedureId: data.procedure ? { id: data.procedure.id, name: data.procedure.name, type: data.procedure.type } : null,
      physicianId: { id: data.physicians[0]?.id, fullName: data.physicians[0]?.fullName },
      facilityId: data.facility ? { id: data.facility.id, name: data.facility.name } : null,
      facilityContact: null,
      protocolNumber: data.protocolNumber ? data.protocolNumber : '',
      description: data.description ? data.description : '',
      principalInvestigator: data.principalInvestigator ? data.principalInvestigator : '',
      date: data.datetime ? date : '',
      time: data.datetime && showTime ? h + ':' + m : '',
      representativeId: representative,
      representativeDate: data.datetime ? repDate : '',
      representativeTime: data.datetime && showTime ? repH + ':' + repM : '',
      healthRecordId: data.healthRecordId ?? ''
    });
    this.formNameValueChangeEmitter.emit(this.required);
    setTimeout(() => {
      if (this.allowEdit) {
        this.required.enable();
      } else {
        this.required.disable();
      }
      // permissions disable for edit
      this.required.controls['eventType'].disable();

      if (!this.isAvailableToChangeRepresentative()) {
        this.required.controls['representativeId'].disable();
      }

      this.required.get('representativeDate').disable();
      this.required.get('representativeTime').disable();
      this.isActiveLocalTime = true;
      if (data.facilityStockEvent) {
        this.required.get('facilityId')?.disable();
      }
      this.ref.detectChanges();
    });
  }

  private setRepresentative(user: UserModel): void {
    if (!this.required || !user) {
      return;
    }
    this.required.patchValue({ representativeId: user });
    this.selectedCustodyEmitter.emit(user);
    this.required.controls['representativeId'].disable();
    this.representativeWasSet = true;
  }

  private setAIData(data: IAIEventCreation): void {
    this.required.get('eventType').disable();
    //Set representative
    const currentUser = UsersService.getUser();
    if (currentUser.role === 'SALES') {
      this.setRepresentative(currentUser);
      this.required.get('date').enable();
      this.required.get('time').enable();
      this.setDateTime();
    }
    if (currentUser.role !== 'SALES' && data.representativeInfo.representativeNameToFind?.length) {
      if (data.representativeInfo.foundRepresentativeInfos?.length) {
        this.required.patchValue({
          representativeId: {
            id: data.representativeInfo.foundRepresentativeInfos[0].representativeId,
            name: data.representativeInfo.foundRepresentativeInfos[0].name
          }
        });
        this.required.get('date').enable();
        this.required.get('time').enable();
        this.setDateTime();
      } else {
        this.required.patchValue({
          representativeId: {
            name: data.representativeInfo.representativeNameToFind,
            id: false
          }
        });
        this.isShowInputsInvalidStateOnInit = true;
      }
    }
    //Set patientId
    if (data.patientId) {
      this.required.patchValue({ healthRecordId: data.patientId });
    }
    // Set physician
    if (data.physicianInfo.physicianNameToFind?.length) {
      if (data.physicianInfo.foundRepresentativeInfos?.length) {
        this.required.patchValue({
          physicianId: {
            id: data.physicianInfo.foundRepresentativeInfos[0]?.physicianId,
            fullName: data.physicianInfo.foundRepresentativeInfos[0].name
          }
        });
      } else {
        this.required.patchValue({
          physicianId: {
            fullName: data.physicianInfo.physicianNameToFind
          }
        });
        this.isShowInputsInvalidStateOnInit = true;
      }
    }
    // Set facility
    if (data.facilityInfo.facilityNameToFind?.length) {
      if (data.foundedFacility) {
        this.required.patchValue({ facilityId: data.foundedFacility });
        this.checkFacilityCreditHold(data.foundedFacility);
        if (this.required.get('eventType').value === 'STOCKING_ORDER') {
          this.setFacilityContacts(data.foundedFacility);
        }
      } else {
        if (data.facilityInfo.foundFacilityInfos?.length) {
          this.required.patchValue({
            facilityId: { id: data.facilityInfo.foundFacilityInfos[0].facilityId, name: data.facilityInfo.foundFacilityInfos[0].name }
          });
        } else {
          this.required.patchValue({
            facilityId: { name: data.facilityInfo.facilityNameToFind }
          });
          this.isShowInputsInvalidStateOnInit = true;
        }
      }
    }
    // Set procedure
    if (data.procedureInfo.procedureNameToFind?.length) {
      if (data.procedureInfo.foundProcedureInfos?.length) {
        this.required
          .get('procedureId')
          .setValue({ id: data.procedureInfo.foundProcedureInfos[0].procedureId, name: data.procedureInfo.foundProcedureInfos[0].name });
      } else {
        this.required.get('procedureId').setValue({ name: data.procedureInfo.procedureNameToFind });
        this.isShowInputsInvalidStateOnInit = true;
      }
    }
  }

  private setDateTime(): void {
    const dateTimeString = this.predefinedDateTime.dateTime;
    let h, m;
    if (typeof dateTimeString === 'string') {
      const date = new Date(dateTimeString);
      h = String(date.getUTCHours()).padStart(2, '0');
      m = String(date.getUTCMinutes()).padStart(2, '0');
    } else {
      h = dateTimeString.getHours() < 10 ? '0' + dateTimeString.getHours() : dateTimeString.getHours();
      m = dateTimeString.getMinutes() < 10 ? '0' + dateTimeString.getMinutes() : dateTimeString.getMinutes();
    }
    this.required.patchValue({ date: dateTimeString });
    if (this.predefinedDateTime?.setTime) {
      this.required.patchValue({ time: h + ':' + m });
    }
  }
}
