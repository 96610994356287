import { ChangeDetectionStrategy, Component, OnInit, inject, signal } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DestroySubscriptions } from '@shared/classes/destroy-subscriptions';
import { NavbarRoutes } from '@shared/utils/navbar-routes';
import { isDevice } from '@shared/utils/is-device';
import { takeUntil } from 'rxjs';
import { AuthInterceptor } from '../../../core/interceptors/auth.interceptor';

@Component({
  selector: 'app-back-prev-page',
  templateUrl: './back-prev-page.component.html',
  styleUrls: ['./back-prev-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BackPrevPageComponent extends DestroySubscriptions implements OnInit {
  private router = inject(Router);

  isShow = signal<boolean>(false);

  /** Back button is showing only on detail pages*/
  backToPrevPage(): void {
    let prevUrl: string = this.modifyUrl(AuthInterceptor.previousUrl);
    if (!prevUrl) {
      if (this.router.url.includes('/users/')) {
        prevUrl = 'users';
      } else {
        prevUrl = this.router.url.includes('/edit') ? this.router.url.split('/edit')[0] + '/list' : null;
      }
    } else if (prevUrl.includes('/edit') && this.router.url.includes('/edit')) {
      prevUrl = this.router.url.split('/edit')[0] + '/list';
    } else if (this.router.url.includes('events/edit')) {
      // on the event detail page, tabs have different urls
      prevUrl = 'events/list';
    }
    if (prevUrl?.length) {
      this.router.navigateByUrl(prevUrl);
    }
  }

  ngOnInit(): void {
    this.checkBackButton();
    if (isDevice('MOBILE') || isDevice('TABLET')) {
      this.router.events.pipe(takeUntil(this.subscriptions)).subscribe(val => {
        if (val instanceof NavigationEnd) {
          this.checkBackButton();
        }
      });
    }
  }

  private checkBackButton(): void {
    const forbiddenRoutes: string[] = NavbarRoutes.mobilePrimaryRoutes;
    const isForbiddenRoute: boolean = forbiddenRoutes.some(r => r === window.location.pathname);
    this.isShow.set(!isForbiddenRoute);
  }

  /**
   * CC-4351 - feature, CC-4786 - bug
   * If a user opens the details via the auto-open feature and then clicks on the back arrow, it reopens the details page again.
   * This function removes the `search` value from the URL to prevent the issue.
   */
  private modifyUrl(url: string): string {
    if (!url) {
      return '';
    }

    const urlParts: string[] = url.split('?');

    if (urlParts.length > 1) {
      const baseUrl: string = urlParts[0];
      const queryParams: string[] = urlParts[1].split('&');
      const filteredParams: string[] = queryParams.filter(param => !param.startsWith('seh='));
      const modifiedUrl: string = baseUrl + (filteredParams.length > 0 ? '?' + filteredParams.join('&') : '');

      return modifiedUrl;
    } else {
      return url;
    }
  }
}
