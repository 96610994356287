import { AfterViewInit, Component, NgZone, inject, signal, WritableSignal } from '@angular/core';
import { environment } from '@environment';
import { SwUpdate } from '@angular/service-worker';
import { AlertsService } from '@services/internal/alerts.service';
import { DestroySubscriptions } from '@shared/classes/destroy-subscriptions';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs';
import { GlobalStoreService } from '@services/internal/global-store.service';
import { LanguageService } from '@services/internal/language.service';
import { AnalyticsService } from '@services/internal/analytics.service';
import { AppUpdate } from '@capawesome/capacitor-app-update';
import { AppUpdateAvailability } from '@capawesome/capacitor-app-update/dist/esm/definitions';
import { GlobalErrorHandlerService } from '@services/internal/global-error-handler.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends DestroySubscriptions implements AfterViewInit {
  private swUpdate = inject(SwUpdate);
  private alertsService = inject(AlertsService);
  private router = inject(Router);
  private zone = inject(NgZone);
  private analyticsService = inject(AnalyticsService);
  private globalErrorHandlerService = inject(GlobalErrorHandlerService);
  /** inject globalStoreService to set page title on non auth pages */
  //@ts-ignore
  private globalStoreService = inject(GlobalStoreService);
  /** inject LanguageService to make multilanguage work */
  //@ts-ignore
  private languageService = inject(LanguageService);

  /** Allow render UI for users that added correct password */
  allowRender: WritableSignal<boolean> = signal<boolean>(false);
  /** Password that users added for non-production environments */
  password: WritableSignal<string> = signal<string>('');
  platform = GlobalStoreService.getPlatform();
  /** Update on swipe to top on iOS/android */
  isPulling = signal(false);
  isRefreshing = signal(false);
  isPullingUp = signal(false);
  private startY: number = 0;
  private isPullingDown: boolean = false;
  private minPullDistance: number = 150;
  private isAtTop: boolean = false;
  private pullDistance: number = 0;

  constructor() {
    super();
    /** Show input to enter password for non-production environments */ // @ts-ignore
    if (AppComponent.isProd() || location.port === '4200' || GlobalStoreService.getPlatform() !== 'web' || window.Cypress) {
      this.allowRender.set(true);
    } else {
      if (AppComponent.getCookie('dev_password') === environment.password) {
        this.allowRender.set(true);
      }
    }
    if (GlobalStoreService.getPlatform() !== 'web') {
      this.initializeDeepUrlsWatcher();
    }
  }

  static isProd = (): boolean => environment.production && location.host === 'beacon.connectsx.com';

  ngAfterViewInit(): void {
    if (AppComponent.isProd()) {
      this.analyticsService.initGA();
      console.log('%cSTOP!', 'color:red; font-size: 36px; font-weight: bold; text-shadow: 1px 1px 1px black; background: yellow;');
      console.log(
        '%cUsing this console may allow attackers to impersonate you and steal your information using an attack called Self-XSS. ' +
          'Do not enter or paste code that you do not understand.',
        'font-size: 18px;'
      );
    }

    if (this.platform === 'web') {
      AppComponent.initDefaultTrustedType();
      /** Check Service Worker updates to reload the page and apply changes */
      if (this.swUpdate.isEnabled) {
        this.swUpdate.versionUpdates.pipe(takeUntil(this.subscriptions)).subscribe(evt => {
          if (evt.type === 'VERSION_READY') {
            this.swUpdate.activateUpdate().then(() => {
              this.alertsService.showWarning('shared.alerts.successMessages.newVersionAvailable');
              document.location.reload();
            });
          }
        });
      }
    } else {
      if (environment.production) {
        (async () => {
          try {
            const updateInfo = await AppUpdate.getAppUpdateInfo();
            if (updateInfo.updateAvailability === AppUpdateAvailability.UPDATE_AVAILABLE) {
              await AppUpdate.openAppStore();
            }
          } catch (error) {
            console.error('Error checking for iOS/android updates:', error);
            this.globalErrorHandlerService.reportFrontEndInternalErrorsToSlack(error, {});
          }
        })();
      }
    }
  }

  /** Check password user added for non-production environments */
  checkPassword(): void {
    if (environment.password === this.password()) {
      this.allowRender.set(true);
      const expiryDate: Date = new Date();
      expiryDate.setMonth(expiryDate.getMonth() + 1);
      document.cookie = `dev_password=${this.password()};expires=${expiryDate.toUTCString()}`;
    }
  }

  /** Refresh page on swipe to top works on iOS/android */
  onTouchStart(event: TouchEvent) {
    this.startY = event.touches[0].clientY;
    this.isAtTop = window.scrollY === 0;
    this.pullDistance = 0;
  }

  onTouchMove(event: TouchEvent) {
    const currentY = event.touches[0].clientY;
    this.pullDistance = currentY - this.startY; // Рассчитываем дистанцию свайпа

    if (this.isAtTop) {
      if (this.pullDistance > 0) {
        // swipe down
        this.isPulling.set(true);
        this.isPullingDown = true;
        this.isPullingUp.set(false);
        document.querySelector('.refresh-container')?.classList.add('pulling');
      } else if (this.pullDistance < 0) {
        //swipe up
        this.isPullingUp.set(true);
        this.isPullingDown = false;
        this.isPulling.set(false);
        document.querySelector('.refresh-container')?.classList.add('pulling-up');
      } else {
        this.isPulling.set(false);
        this.isPullingUp.set(false);
        document.querySelector('.refresh-container')?.classList.remove('pulling', 'pulling-up');
      }
    }
  }

  onTouchEnd() {
    if (this.isAtTop && this.isPullingDown && this.pullDistance >= this.minPullDistance) {
      this.startRefreshing();
    } else {
      this.resetPull();
    }
  }

  startRefreshing() {
    this.isRefreshing.set(true);
    this.refreshPage();
    setTimeout(() => {
      this.isRefreshing.set(false);
      this.resetPull();
    }, 2000);
  }

  resetPull() {
    this.isPulling.set(false);
    this.isPullingDown = false;
    this.isPullingUp.set(false);
    this.isAtTop = false;
    this.pullDistance = 0;
    document.querySelector('.refresh-container')?.classList.remove('pulling', 'pulling-up');
  }

  refreshPage() {
    window.location.reload();
  }
  /** End reload on swipe to top */

  /** CheckPassword in cookies for non-production environments */
  private static getCookie(name: string): string {
    const matches: string[] = document.cookie.match(
      new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)')
    );
    return matches ? decodeURIComponent(matches[1]) : null;
  }

  /** Default TrustedType */
  private static initDefaultTrustedType(): void {
    // @ts-ignore
    if (window.trustedTypes?.createPolicy) {
      // @ts-ignore
      window.trustedTypes.createPolicy('default', {
        createHTML: (str: string) => str.replace(/\</g, '&lt;'),
        createScriptURL: (str: string) => str, // warning: this is unsafe!
        createScript: (str: string) => str
      });
    }
  }

  private initializeDeepUrlsWatcher() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        const slug = event.url.split('connectsx.com').pop();
        if (slug?.length) {
          this.router.navigateByUrl(slug);
        }
      });
    });
  }
}
