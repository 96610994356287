import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ProcedureService } from '@services/procedure.service';
import { BehaviorSubject, firstValueFrom, take } from 'rxjs';
import { PageableModel, PhysicianModel, ProcedureModel, UserModel } from '@shared/models';
import { DisplayName } from '@shared/utils/form-elements/display-with-autocomplete';
import { IFilter } from '@shared/interfaces';
import { ProcedurePageableParams } from '@shared/models/build-models';
import { FilterUniqueArray } from '@shared/utils/arrays/filter-unique-array';
import { TIcons } from '@shared/type/icons.type';
import { CreateProcedureComponent } from 'src/app/features/directory/procedures/partials/modals/create-procedure/create-procedure.component';
import { CreateSmallEntityConfig } from '@constants';
import { UsersService } from '@services/users.service';
import { PermissionService } from '@services/internal/permission.service';
import { TranslationsKeys } from '@shared/type/i18n.type';

@Component({
  selector: 'app-procedure-autocomplete',
  templateUrl: './procedure-autocomplete.component.html',
  styleUrls: ['./procedure-autocomplete.component.scss']
})
export class ProcedureAutocompleteComponent {
  @Input() procedure: ProcedureModel = new ProcedureModel();
  @Input() filterView: boolean = false;
  @Input() multiple: boolean = false;
  @Input() showAddNewButton: boolean = false;
  @Input() selectedItems: ProcedureModel[] = [];
  @Input() removable: boolean = false;
  @Input() showOpenInNewIcon: boolean = false;
  @Input() showClearIcon: boolean = false;
  @Input() required: boolean = false;
  @Input() physicians: PhysicianModel[] = [];
  @Input() tooltip: TranslationsKeys;
  @Input() disabled: boolean = false;
  @Input() invalid: boolean = false;
  @Input() representative: UserModel = new UserModel();
  @Input() showInvalidStateOnInit: boolean = false;

  @Output() removeChipEmitter = new EventEmitter<string>();
  @Output() selectedOptionEmitter = new EventEmitter<ProcedureModel>();
  @Output() valueChangedEmitter = new EventEmitter<string>();
  @Output() clearInputEmitter = new EventEmitter<void>();

  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  initialLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  searchedData: ProcedureModel[] = [];
  forceBlurEvent: boolean = false;

  readonly displayFn = DisplayName;

  constructor(
    public permissionService: PermissionService,
    private dialog: MatDialog,
    private procedureService: ProcedureService
  ) {}

  async createProcedure(): Promise<void> {
    await import('../../../../../features/directory/procedures/procedures.module');
    const dialogConfig: MatDialogConfig = { ...CreateSmallEntityConfig };
    dialogConfig.height = 'auto';
    dialogConfig.data = false;

    const dialogRef = this.dialog.open(CreateProcedureComponent, dialogConfig);

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(id => {
        if (!id) {
          return;
        }

        this.procedureService
          .getProcedure(id)
          .pipe(take(1))
          .subscribe(procedure => {
            this.selectOption(procedure);
          });
      });
  }

  async searchProcedure(value: string, skipSelectSingleOption: boolean = false): Promise<void> {
    this.initialLoading$.next(false);
    this.forceBlurEvent = false;

    if (this.loading$.value) {
      return;
    }

    this.loading$.next(true);
    this.searchedData = [];

    const params: IFilter = {
      search: value,
      size: 20,
      state: 'ACTIVE'
    };

    if (UsersService.getUser().organization.showProcedureAssignedToPhysician && this.physicians.length) {
      params.physicianIds = this.physicians.filter(p => p).map(p => p.id);
    } else {
      params.physicianIds = [];
    }

    const queryParams: ProcedurePageableParams = new ProcedurePageableParams(params);
    if (!this.permissionService.isRole('SALES') && this.representative?.id) {
      queryParams.representativeId = this.representative.id;
    }

    let data: PageableModel<ProcedureModel>;
    if (this.permissionService.isRole('REGIONAL_MANAGER')) {
      data = await firstValueFrom(this.procedureService.getPageableByRepresentative(queryParams));
    } else {
      data = await firstValueFrom(this.procedureService.getPageable(queryParams));
    }

    this.loading$.next(false);
    if (this.multiple) {
      this.searchedData = FilterUniqueArray(data.content, this.selectedItems);
    } else {
      this.searchedData = data.content;
    }

    if (skipSelectSingleOption && this.searchedData.length === 1) {
      this.forceBlurEvent = true;
      return;
    }

    if (!value && this.searchedData.length === 1) {
      this.selectOption(this.searchedData[0]);
      this.forceBlurEvent = true;
    } else {
      this.forceBlurEvent = false;
    }
  }

  removeProcedureChip(procedureId: string): void {
    this.removeChipEmitter.emit(procedureId);
  }

  selectOption(procedure: ProcedureModel): void {
    this.selectedOptionEmitter.emit(procedure);
    if (this.multiple) {
      //Hide selected item from search results
      this.searchedData = this.searchedData.filter(c => c.id !== procedure.id);
    }
  }

  doIconAction(icon: TIcons): void {
    if (icon === 'close') {
      this.clearInputEmitter.emit();
      this.searchProcedure('', true);
    }
  }
}
