import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ManufacturerService } from '@services/manufacturer.service';
import { ManufacturerModel, UserModel } from '@shared/models';
import { BehaviorSubject, finalize, take } from 'rxjs';
import { DisplayName } from '@shared/utils/form-elements/display-with-autocomplete';
import { IFilter } from '@shared/interfaces';
import { ManufacturersPageableParams } from '@shared/models/build-models';
import { FilterUniqueArray } from '@shared/utils/arrays/filter-unique-array';
import { TIcons } from '@shared/type/icons.type';
import { LanguageService } from '@services/internal/language.service';
import { PermissionService } from '@services/internal/permission.service';
import { UserAssignmentsService } from '@services/user-assignments.service';

@Component({
  selector: 'app-manufacturer-autocomplete',
  templateUrl: './manufacturer-autocomplete.component.html',
  styleUrls: ['./manufacturer-autocomplete.component.scss']
})
export class ManufacturerAutocompleteComponent {
  @Input() manufacturer: ManufacturerModel = new ManufacturerModel();
  @Input() selectedItems: ManufacturerModel[] = [];
  @Input() filterView: boolean = false;
  @Input() multiple: boolean = false;
  @Input() required: boolean = false;
  @Input() removable: boolean = false;
  @Input() user: UserModel = new UserModel();
  @Input() showLinkToDetail: boolean = false;
  @Input() label: string = LanguageService.instant('shared.components.manufacturers');
  @Input() disabled: boolean = false;
  @Input() borderTransparent: boolean = false;
  @Input() showAddNew: boolean = false;
  @Input() invalid: boolean = false;
  @Input() sortBy: string = 'modifiedDatetime';

  @Output() removeChipEmitter = new EventEmitter<string>();
  @Output() selectedOptionEmitter = new EventEmitter<ManufacturerModel>();
  @Output() clearInputEmitter = new EventEmitter<void>();
  @Output() valueChangedEmitter = new EventEmitter<string>();
  @Output() addNewEmitter = new EventEmitter<void>();

  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  initialLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  searchedData$: BehaviorSubject<ManufacturerModel[]> = new BehaviorSubject<ManufacturerModel[]>([]);
  forceBlurEvent: boolean = false;

  readonly displayFn = DisplayName;

  constructor(
    public permissionService: PermissionService,
    private userAssignmentsService: UserAssignmentsService,
    private manufacturerService: ManufacturerService
  ) {}

  removeManufacturer(id: string): void {
    this.removeChipEmitter.emit(id);
  }

  selectOption(manufacturer: ManufacturerModel): void {
    this.selectedOptionEmitter.emit(manufacturer);
    if (this.multiple) {
      //Hide selected item from search results
      this.searchedData$.next(this.searchedData$.value.filter(c => c.id !== manufacturer.id));
    }
  }

  doIconAction(icon: TIcons): void {
    if (icon === 'close') {
      this.clearInputEmitter.emit();
      this.searchManufacturer('', true);
    }
  }

  searchManufacturer(value: string, skipSelectSingleOption: boolean = false): void {
    this.initialLoading$.next(false);
    this.forceBlurEvent = false;

    if (this.loading$.value) {
      return;
    }

    this.loading$.next(true);

    const params: IFilter = {
      search: value,
      size: 20,
      state: 'ACTIVE',
      selectedMatSortHeaderActive: this.sortBy
    };

    const queryParams: ManufacturersPageableParams = new ManufacturersPageableParams(params);

    if (this.user?.role === 'SALES' || this.user?.role === 'DISTRIBUTOR') {
      this.userAssignmentsService
        .getAssignedManufacturers(this.user.id, value)
        .pipe(
          take(1),
          finalize(() => this.loading$.next(false))
        )
        .subscribe(data => {
          this.searchedData$.next(FilterUniqueArray(data, this.selectedItems));
          this.selectSingleOption(value, skipSelectSingleOption);
        });
    } else {
      this.manufacturerService
        .getPageable(queryParams)
        .pipe(
          take(1),
          finalize(() => this.loading$.next(false))
        )
        .subscribe(data => {
          this.searchedData$.next(FilterUniqueArray(data.content, this.selectedItems));
          this.selectSingleOption(value, skipSelectSingleOption);
        });
    }
  }

  private selectSingleOption(value: string, skipSelectSingleOption: boolean = false): void {
    if (skipSelectSingleOption && this.searchedData$.value.length === 1) {
      this.forceBlurEvent = true;
      return;
    }

    if (!value && this.searchedData$.value.length === 1) {
      this.selectOption(this.searchedData$.value[0]);
      this.forceBlurEvent = true;
    } else {
      this.forceBlurEvent = false;
    }
  }
}
