@if (allowRender()) {
  @if (platform === 'web') {
    <router-outlet />
  } @else {
    <div class="refresh-container" (touchstart)="onTouchStart($event)" (touchmove)="onTouchMove($event)" (touchend)="onTouchEnd()">
      <div *ngIf="isPulling()" class="refresh-icon">
        <app-icon *ngIf="!isRefreshing()" [icon]="'arrow_down_circle'" />
        <mat-progress-spinner *ngIf="isRefreshing()" value="30" mode="indeterminate" diameter="30" />
      </div>
      <router-outlet />
    </div>
  }
} @else {
  <div class="devGuardBg">
    <app-input-kit
      [dataCy]="'welcomePassword'"
      [required]="true"
      [type]="'password'"
      [initialValue]="password()"
      [label]="'Password'"
      (valueChangedEmitter)="password.set($event)"
      (enterEmitter)="checkPassword()"
    />
  </div>
}
